/* eslint-disable react/no-unescaped-entities */
import React from 'react';
// import { useEffect } from "react";
// import Box from '@mui/material/Box';
// import Card from '@mui/material/Card';
// import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Link from '@mui/material/Link';
// import Container from 'components/Container';
// import { useFormik } from 'formik';
// import axios from 'axios';
// import { useState } from 'react';


// const [initialized, setInitialized] = useState(false);
// const [windowLoaded, setWindowLoaded] = useState(false);

const UpdateMessage = () => {


  // const initialValues = {
  //   announcement: '',
  //   id: '',
  // };

  // useEffect(() => {
  //   if (!windowLoaded){
  //   if (typeof window !== 'undefined' && window){
  //   setWindowLoaded(true)
  //   }
  //   }
  //   }, [windowLoaded]);
  
  //   useEffect(() => {
  //     if (!initialized && windowLoaded) {
  //       let token_Data = localStorage.getItem("token_Data");
  //     StorageHelpers.initiateStorage();
  //       setInitialized(true);
  //     }
  //     }, [initialized, windowLoaded]);
  



// var config = {
//   method: 'put',
//   url: 'https://ut3oa1hbvk.execute-api.ap-south-1.amazonaws.com/apiv1/npsypr/announcements', 
//   headers: {
//     Authorization: `Bearer ${token_Data}`,
//   },
// };


  // const onSubmit = () => {
  //   axios(config)
  //   .then(function (response) {
  //     console.log(JSON.stringify(response.data));
  //     alert('Hi');

  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });
  // };

 
  
  // const formik = useFormik({
  //   initialValues,
  //   onSubmit,
  //   initialValues: initialValues,
  //   onSubmit: onSubmit,
  // });


  return (
    <div></div>
    // <Box bgcolor={'alternate.main'}>
    //   <Container maxWidth={600}>
    //     <Box marginBottom={4}>
        
    //       <Typography
    //         variant="h4"
    //         sx={{
    //           fontWeight: 700,
    //         }}
    //       >
    //         Update Announcements here
    //       </Typography>
    //       <Typography color="text.secondary">
    //         Ex Date Format: 15 August 2022
    //       </Typography>
    //     </Box>
    //     <Card sx={{ p: { xs: 4, md: 6 } }}>
    //       <form>
    //         <Grid container spacing={4}>
    //           <Grid item xs={12}>
    //           <TextField
    //             sx={{ height: 54 }}
    //             label="Annoucement"
    //             type="announcement"
    //             variant="outlined"
    //             color="primary"
    //             size="medium"
    //             name={'announcement'}
    //             fullWidth
    //             defaultValue={formik.values.announcement}
    //             onChange={formik.handleChange('announcement')}
    //             error={
    //               formik.touched.announcement || Boolean(formik.errors.announcement)
    //             }
    //             helperText={formik.touched.announcement || formik.errors.announcement}
    //           />
    //           </Grid>
    //           <Grid item xs={12}>
    //           <TextField
    //             sx={{ height: 54 }}
    //             label="Update Date"
    //             type="id"
    //             variant="outlined"
    //             color="primary"
    //             size="medium"
    //             name={'id'}
    //             fullWidth
    //             defaultValue={formik.values.id}
    //             onChange={formik.handleChange('id')}
    //             error={
    //               formik.touched.id || Boolean(formik.errors.id)
    //             }
    //             helperText={formik.touched.id || formik.errors.id}
    //           />
    //           </Grid>
    //           <Grid item container xs={12}>
    //             <Box
    //               display="flex"
    //               flexDirection={{ xs: 'column', sm: 'row' }}
    //               alignItems={{ xs: 'stretched', sm: 'center' }}
    //               justifyContent={'space-between'}
    //               width={1}
    //               maxWidth={600}
    //               margin={'0 auto'}
    //             >
    //               <Box marginBottom={{ xs: 1, sm: 0 }}>
    //                 <Button
    //                   size={'large'}
    //                   variant={'outlined'}
    //                   component={Link}
    //                   href={'/Announcement'}
    //                   fullWidth
    //                 >
    //                   Back
    //                 </Button>
    //               </Box>
    //               <Button
    //                size={'large'} 
    //                variant={'contained'} 
    //                type={'submit'}
    //                onClick={onSubmit}>
    //                 Submit
    //               </Button>
    //             </Box>
    //           </Grid>
    //         </Grid>
    //       </form>
    //     </Card>
    //   </Container>
    // </Box>
  );
};

export default UpdateMessage;